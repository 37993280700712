<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW PARTICULARS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="particulars"
              label="Particulars"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="save_request"
              v-if="!saving_data"
            >
              Save Request
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
          <v-col class="mt-2">
            <h4> LEGENDS -
              (Deactivate-
              <v-icon
                class="mr-2"
                color="warning"
              >
                {{icons.mdiAccountOff}}
              </v-icon>
              ),
              (Activate-
              <v-icon
                class="mr-2"
                color="info"
              >
                {{icons.mdiAccountCheck}}
              </v-icon>
              )
            </h4>
          </v-col>
        </v-layout>
      </v-form>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Particulars
                </th>
                <th class="text-uppercase">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in active_items"
                :key="item.id"
              >
                <td>
                  {{ (active_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.particulars }}
                </td>
                <td>
                  <div>
                    <v-icon
                      v-if="!is_deleting && item.is_active===1"
                      class="mr-2"
                      color="warning"
                      @click="change_status(item.id,0)"
                    >
                      {{icons.mdiAccountOff}}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-if="is_deleting"></v-progress-circular>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">
                  No.
                </th>
                <th class="text-uppercase">
                  Particulars
                </th>
                <th class="text-uppercase">
                  Action
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in deactive_items"
                :key="item.id"
              >
                <td>
                  {{ (deactive_items
                  .map(function (x) {
                  return x.id
                  })
                  .indexOf(item.id)+1) }}
                </td>
                <td>
                  {{ item.particulars }}
                </td>
                <td>
                  <div>
                    <v-icon
                      v-if="!is_deleting && item.is_active===0"
                      class="mr-2"
                      color="info"
                      @click="change_status(item.id,1)"
                    >
                      {{icons.mdiAccountCheck}}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-if="is_deleting"></v-progress-circular>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      category_id: '',
      category_items: [],
      active_items: [],
      deactive_items: [],
      particulars: '',
      tab: '',
      tabs: [
        {title: 'ACTIVE', icon: mdiCheck},
        {title: 'DEACTIVATED', icon: mdiClose},
      ],

      is_deleting: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('request_particulars', ['initialize_new_particulars', 'request_particulars_data', 'register_particulars','change_status_of_particulars']),
      initialize_data() {
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.is_deleting = false
        const data = new FormData()
        data.append('category_id', this.category_id);
        this.request_particulars_data(data)
          .then(response => {
            this.active_items = response.data[0].active
            this.deactive_items = response.data[0].deactivate
          })
          .catch(error => {
            console.log(error)
          })
      },
      change_status(value, status) {
        this.is_deleting = true
        const data = new FormData()
        data.append('id', value)
        data.append('status', status)
        this.change_status_of_particulars(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.selected_category()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('category_id', this.category_id);
          data.append('particulars', this.particulars);
          this.register_particulars(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                var color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              Object.assign(this.$data, initialState())
              this.initialize_data()
              this.category_id = cat
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    },
  }
</script>
